import {Injectable} from '@angular/core';
import {NotificationService} from '@modules/notification';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  startWith,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {FileDownloaderService} from '@directives/file-downloader';
import {Store} from '@helpers/store';
import {MediaPreSignedPayload} from '@models/cloud-media-uploader';
import {ResponseDto} from '@models/response';
import {ChangeUserDocumentStatusPayload, UserDocumentItemDto} from '@models/user';
import {UserService} from '@services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserDownloadNotificationService extends Store<UserDocumentItemDto> {

  private updateNotification$: Subject<void>;

  constructor(
    private readonly userService: UserService,
    private readonly fileDownloaderService: FileDownloaderService,
    private readonly notificationService: NotificationService,
    private readonly appStateService: AppStateService,
  ) {
    super();
  }

  init(): void {
    super.init();
    this.updateNotification$ = new Subject<void>();
  }

  loadItems(): void {
    const sub = combineLatest([
      this.appStateService.user$.pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id)),
      this.updateNotification$.pipe(startWith(null), throttleTime(2000)),
    ]).pipe(
      throttleTime(2000),
      switchMap(([user]) => {
        if (!user) {
          return of([]);
        }
        return this.userService.getUnViewedDownloadFilesList();
      }),
    ).subscribe(data => {
      this.setState(data);
    },
    );
    this.subscription.add(sub);
  }

  updateList(): void {
    if (!this.updateNotification$ || this.updateNotification$.closed) {
      return;
    }
    this.updateNotification$.next();
  }

  downloadDocument(item: UserDocumentItemDto): Observable<boolean> {
    const params: MediaPreSignedPayload = {key: `${item.originFilename}`};
    return this.fileDownloaderService.getCloudPreSignedUrlByUploadPath(params).pipe(
      switchMap(data => this.fileDownloaderService.downloadFileByPreSignedUrl(data, null, item.fileName)),
      catchError((err) => {
        if (err.message) {
          this.notificationService.error(err.message);
        }
        return of(false);
      }),
      take(1),
    );
  }

  setDownloadStatus(items: UserDocumentItemDto[]): Observable<ResponseDto> {
    const ids = items.map(item => item.id);
    const payload = new ChangeUserDocumentStatusPayload(ids);
    return this.userService.setDocumentDownloadStatus(payload)
      .pipe(
        tap(res => {
          if (res) {
            this.updateFileDownloadStatus(ids);
          }
        }),
      );
  }

  setViewedStatus(items: UserDocumentItemDto[]): Observable<ResponseDto> {
    const ids = items.map(item => item.id);
    const payload = new ChangeUserDocumentStatusPayload(ids);
    return this.userService.setDocumentViewedStatus(payload)
      .pipe(
        tap(res => {
          if (res) {
            ids.forEach(id => {
              this.removeFromStore(id);
            });
          }
        }),
      );
  }

  private updateFileDownloadStatus(ids: number[]): void {
    const state = this.state;
    if (!state) {
      return;
    }
    const newList = state.map(item => {
      if (ids.some(id => id === item.id)) {
        return {...item, downloaded: true};
      }
      return item;
    });
    this.setState(newList);
  }

  destroy(): void {
    super.destroy();
    this.updateNotification$.complete();
  }
}
